<template>
  <v-container fluid>
    <v-card style="font-family: NotoSanLao;">
      <v-card-title>
        ລົງທະບຽນລູກຄ້າ
      </v-card-title>
      <v-card-text>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form ref="form" @submit.prevent="submit">
            <v-row>
              <v-col cols="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="ຊື່"
                  :rules="{
                    required: true,
                    max: 20
                  }"
                >
                  <v-text-field
                    v-model="firstName"
                    :counter="20"
                    label="ຊື່"
                    outlined
                    shaped
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>

              <v-col cols="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="ນາມສະກຸນ"
                  :rules="{
                    required: true,
                    max: 20
                  }"
                >
                  <v-text-field
                    v-model="lastName"
                    :counter="20"
                    label="ນາມສະກຸນ"
                    outlined
                    shaped
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="ເບີໂທລະສັບ"
                  :rules="{
                    required: true
                    // numeric: true,
                    // digits: 11
                  }"
                >
                  <v-text-field
                    v-model="phone"
                    :counter="12"
                    label="ເບີໂທລະສັບ"
                    outlined
                    shaped
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="ອີເມວ"
                  :rules="{
                    required: true,
                    email: true
                  }"
                >
                  <v-text-field
                    v-model="email"
                    label="ອີເມວ"
                    outlined
                    shaped
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="ວັນ, ເດືອນ, ປີເກີດ"
                  :rules="{
                    required: true
                  }"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="birthday"
                        label="ວັນ ເດືອນ ປີເກີດ"
                        readonly
                        v-bind="attrs"
                        outlined
                        shaped
                        :error-messages="errors"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="birthday"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substring(0, 10)
                      "
                      min="1940-01-01"
                      @change="save"
                    />
                  </v-menu>
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="ເລກໜັງສືເດີນທາງ"
                  :rules="{
                    required: true
                  }"
                >
                  <v-text-field
                    v-model="passportNo"
                    label="ເລກໜັງສືເດີນທາງ"
                    outlined
                    shaped
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-card-title>
              ຂໍ້ມູນປະກັນໄພ
            </v-card-title>
            <v-row>
              <v-col cols="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="ປະເພດປະກັນ"
                  rules="required"
                >
                  <v-select
                    v-model="product"
                    :items="products"
                    label="ປະເພດປະກັນ"
                    outlined
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="ແພັກເກັດ"
                  rules="required"
                >
                  <v-select
                    v-model="cvPackage"
                    :items="packages"
                    label="ແພັກເກັດ"
                    outlined
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="ຈຳນວນ"
                  :rules="{
                    required: true,
                    numeric: true
                  }"
                >
                  <v-text-field
                    v-model="amount"
                    label="ຈຳນວນ"
                    value=""
                    prefix="₭"
                    outlined
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="ປະເພດ"
                  rules="required"
                >
                  <v-select
                    v-model="cvType"
                    :items="types"
                    label="ປະເພດ"
                    outlined
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="ສະຖານະ"
                  rules="required"
                >
                  <v-select
                    v-model="status"
                    :items="items"
                    label="ສະຖານະ"
                    outlined
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="ວັນໝົດອາຍຸ"
                  :rules="{
                    required: true
                  }"
                >
                  <v-menu
                    ref="expired"
                    v-model="expired"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="expireDate"
                        label="ວັນໝົດອາຍຸ"
                        readonly
                        v-bind="attrs"
                        outlined
                        shaped
                        :error-messages="errors"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="expireDate"
                      :active-picker.sync="activePicker"
                      :min="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substring(0, 10)
                      "
                      max="2050-01-01"
                      @change="saveExpire"
                    />
                  </v-menu>
                </validation-provider>
              </v-col>
            </v-row>
            <v-btn
              class="mr-4"
              type="submit"
              color="primary"
              :disabled="invalid"
            >
              ບັນທືກ
            </v-btn>
            <v-btn @click="clear">
              ລືບ
            </v-btn>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import QRCode from "qrcode";
export default {
  data: () => ({
    customValue: null,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    passportNo: "",
    amount: "",
    status: null,
    activePicker: null,
    birthday: null,
    menu: false,
    expired: false,
    expireDate: "",
    items: [
      { text: "PAID", value: "PAID" },
      { text: "PENDING", value: "PENDING" }
    ],
    products: [
      { text: "CV Insurance", value: "CV Insurance" },
      { text: "Health Insurance", value: "Health Insurance" }
    ],
    product: "",
    packages: [
      { text: "CV1 (Full Year)", value: "CV1 (Full Year)" },
      { text: "CV2 (Half Year)", value: "CV2 (Half Year)" }
    ],
    cvPackage: "",
    types: [
      { text: "Single", value: "Single" },
      { text: "Family", value: "Family" }
    ],
    cvType: ""
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    expired(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  },
  methods: {
    save(birthday) {
      this.$refs.menu.save(birthday);
    },
    saveExpire(expireDate) {
      this.$refs.expired.save(expireDate);
    },
    submit() {
      this.$refs.observer.validate().then(result => {
        if (result) {
          this.generateQR();
        } else {
          alert("Failure!");
        }
      });
    },
    clear() {
      this.firstName = "";
      this.lastName = "";
      this.phone = "";
      this.email = "";
      this.passportNo = "";
      this.amount = "";
      this.status = "";
      this.birthday = "";
      this.product = "";
      this.cvPackage = "";
      this.cvType = "";
      this.expireDate = "";
      this.$refs.observer.reset();
    },
    async generateQR() {
      try {
        // Generate QR code content using current year, month, and three random numbers
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const randomNumbers = Math.floor(Math.random() * 1000000);
        const qrContent = `${year}${month}-${randomNumbers}`;

        // Generate QR code image data URL
        const qrCodeDataUrl = await QRCode.toDataURL(qrContent);

        // Store the generated QR code data URL and info
        this.latestQRInfo = {
          qrCode: qrCodeDataUrl,
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          email: this.email,
          passportNo: this.passportNo,
          amount: this.amount,
          status: this.status,
          birthday: this.birthday,
          product: this.product,
          cvPackage: this.cvPackage,
          cvType: this.cvType,
          expireDate: this.expireDate
        };

        // Send the QR code data along with additional information to the server
        const response = await axios.post(
          "https://node-qrcode-scanner.onrender.com/addMember",
          {
            qrCode: qrContent,
            firstName: this.firstName,
            lastName: this.lastName,
            phone: this.phone,
            email: this.email,
            passportNo: this.passportNo,
            amount: this.amount,
            status: this.status,
            birthday: this.birthday,
            productType: this.product,
            productPackage: this.cvPackage,
            buyType: this.cvType,
            expireDate: this.expireDate
          }
        );

        console.log(response.data.message);

        // Clear input fields after generating QR code
        await this.clear();
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
<style lang=""></style>
