var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticStyle:{"font-family":"NotoSanLao"}},[_c('v-card-title',[_vm._v(" ລົງທະບຽນລູກຄ້າ ")]),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"ຊື່","rules":{
                  required: true,
                  max: 20
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":20,"label":"ຊື່","outlined":"","shaped":"","error-messages":errors},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"ນາມສະກຸນ","rules":{
                  required: true,
                  max: 20
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":20,"label":"ນາມສະກຸນ","outlined":"","shaped":"","error-messages":errors},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"ເບີໂທລະສັບ","rules":{
                  required: true
                  // numeric: true,
                  // digits: 11
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":12,"label":"ເບີໂທລະສັບ","outlined":"","shaped":"","error-messages":errors},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"ອີເມວ","rules":{
                  required: true,
                  email: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ອີເມວ","outlined":"","shaped":"","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"ວັນ, ເດືອນ, ປີເກີດ","rules":{
                  required: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"ວັນ ເດືອນ ປີເກີດ","readonly":"","outlined":"","shaped":"","error-messages":errors},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker,"max":new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substring(0, 10),"min":"1940-01-01"},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.save},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"ເລກໜັງສືເດີນທາງ","rules":{
                  required: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ເລກໜັງສືເດີນທາງ","outlined":"","shaped":"","error-messages":errors},model:{value:(_vm.passportNo),callback:function ($$v) {_vm.passportNo=$$v},expression:"passportNo"}})]}}],null,true)})],1)],1),_c('v-card-title',[_vm._v(" ຂໍ້ມູນປະກັນໄພ ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"ປະເພດປະກັນ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.products,"label":"ປະເພດປະກັນ","outlined":"","error-messages":errors},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"ແພັກເກັດ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.packages,"label":"ແພັກເກັດ","outlined":"","error-messages":errors},model:{value:(_vm.cvPackage),callback:function ($$v) {_vm.cvPackage=$$v},expression:"cvPackage"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"ຈຳນວນ","rules":{
                  required: true,
                  numeric: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ຈຳນວນ","value":"","prefix":"₭","outlined":"","error-messages":errors},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"ປະເພດ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.types,"label":"ປະເພດ","outlined":"","error-messages":errors},model:{value:(_vm.cvType),callback:function ($$v) {_vm.cvType=$$v},expression:"cvType"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"ສະຖານະ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.items,"label":"ສະຖານະ","outlined":"","error-messages":errors},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"ວັນໝົດອາຍຸ","rules":{
                  required: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-menu',{ref:"expired",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"ວັນໝົດອາຍຸ","readonly":"","outlined":"","shaped":"","error-messages":errors},model:{value:(_vm.expireDate),callback:function ($$v) {_vm.expireDate=$$v},expression:"expireDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.expired),callback:function ($$v) {_vm.expired=$$v},expression:"expired"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker,"min":new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substring(0, 10),"max":"2050-01-01"},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.saveExpire},model:{value:(_vm.expireDate),callback:function ($$v) {_vm.expireDate=$$v},expression:"expireDate"}})],1)]}}],null,true)})],1)],1),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","color":"primary","disabled":invalid}},[_vm._v(" ບັນທືກ ")]),_c('v-btn',{on:{"click":_vm.clear}},[_vm._v(" ລືບ ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }